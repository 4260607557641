import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import store from '@/store'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_TESORERIA);

class Coi
{
  obtener_layouts(options) {
    return http.get(utils.url_options(`/coi`, options));
  }

  crear_layout(payload) {
    return http.post(`/coi`,payload);
  }

  descargar_layout(id) {
    // return axios.get(`${baseURL}/tesoreria/gastos/documentos/${id}`,{responseType: 'blob'});
    return http.get(`/coi/download/${id}`);
  }
}

export default new Coi()